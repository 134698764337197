const state = () => ({
  btnLoading: false,
  isPageLoading: false,
  fromPage: 'frontend',
  breadCrumb: [],
  locales: ['en', 'ja'],
  locale: 'ja',
  errors: {},
})

const actions = {
  SWITCH_LANG ({_, state, commit}, locale) {
    state.locales.includes(locale) && commit('set_lang', locale)
  },
  async nuxtServerInit({dispatch, state}, {req}) {
    const url = req.url;
    if (/^\/admin/.test(url)) {
      state.fromPage = 'admin';
      await dispatch('admin/auth/initAuthState', {req});
    } else {
      state.fromPage = 'frontend';
      await dispatch('frontend/auth/initAuthState', {req});
      await dispatch('frontend/companies/getSelectedCompanyFromCookie')
    }
  },
  async getPaymentMethod({}) {
    var data = await this.$frontendRepositories.stripe.getPaymentMethod();
    return data;
  },
  async getStripeCharge({}, payload) {
    var data = await this.$frontendRepositories.stripe.getStripeCharge(payload);
    return data;
  },
  async submitPayment({}, payload) {
    var data = await this.$frontendRepositories.stripe.submitPayment(payload);
    return data;
  },
  async logClientBugs({}, payload) {
    await this.$frontendRepositories.auth.logClientBugs(payload);
  }
}

const getters = {
  getLocale: (state) => state.locale,
  getBtnLoading: (state) => state.btnLoading,
  getConfigsOfBreadCrumb: state => payload => {
    let homePath = payload == 'admin' ? '/admin' : '/';

    return [
      {
        name: 'dashboard',
        path: homePath
      },
      ...state.breadCrumb
    ]
  }
}
const mutations = {
  setBtnLoading: (state, payload) => state.btnLoading = payload,
  setBreadCrumb: (state, payload) => state.breadCrumb = payload,
  showPageLoading: state => state.isPageLoading = true,
  hidePageLoading: state => state.isPageLoading = false,
  setErrors: (state, payload) => state.errors = payload,
  resetErrors: state => state.errors = {},
  set_lang: (state, locale) => {
    state.locale = locale
    $nuxt.$i18n.locale = locale
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
