import {FRONTEND_VERSION_API} from "@/contants/api";

export default $axios => ({
  verify(token) {
    return $axios.post(`${FRONTEND_VERSION_API}/verify-email-register`, token);
  },
  verifyEmailChangeEmail(token) {
    return $axios.post(`${FRONTEND_VERSION_API}/verify-email-change-email`, token);
  },
  register(userInfo) {
    return $axios.post(`${FRONTEND_VERSION_API}/register`, userInfo);
  },
  login(userInfo) {
    return $axios.post(`${FRONTEND_VERSION_API}/login`, userInfo);
  },
  info() {
    return $axios.get(`${FRONTEND_VERSION_API}/info`);
  },
  logout() {
    return $axios.post(`${FRONTEND_VERSION_API}/logout`);
  },
  update(payload) {
    return $axios.put(`${FRONTEND_VERSION_API}/change-info`, payload);
  },
  updateFirstLogin(payload) {
    return $axios.put(`${FRONTEND_VERSION_API}/update-first-login`, payload);
  },
  logClientBugs(payload) {
    return $axios.post(`${FRONTEND_VERSION_API}/log-client-bugs`, payload);
  }
});
