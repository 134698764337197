//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nav from "./menu";
import {
  Header as AppHeader,
  Sidebar,
  Footer as AppFooter,
  Breadcrumb
} from "~/components/admin";
import { mapState } from "vuex";

export default {
  name: "full",
  middleware: "admin/auth",
  components: {
    AppHeader,
    Sidebar,
    AppFooter,
    Breadcrumb
  },
  head() {
    return {
      title: "Auto-Certificate",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { hid: "description", name: "description", content: "" }
      ],
      link: [
        { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
        { rel: "stylesheet", type: "text/css", href: "/admin/css/style.css" }
      ]
    };
  },
  data() {
    return {
      nav: nav.items
    };
  },
  computed: {
    ...mapState({
      isPageLoading: state => state.admin.loading.isPageLoading
    }),
    name() {
      return this.$route.name;
    },
    list() {
      const { name } = this.$route;
      const items = [];
      let url = "/";
      name.split("-").forEach(val => {
        const item = {};
        item.name = val;
        url += val + "/";
        item.url = url;
        items.push(item);
      });
      return items;
    }
  },
  methods: {
    start() {
      this.$store.commit("admin/loading/showPageLoading");
    },
    finish() {
      this.$store.commit("admin/loading/hidePageLoading");
    }
  }
};
