//
//
//
//
//


export default {
  name: 'sidebar-footer'
}
