const middleware = {}

middleware['admin/auth'] = require('../middleware/admin/auth.js')
middleware['admin/auth'] = middleware['admin/auth'].default || middleware['admin/auth']

middleware['admin/notAuth'] = require('../middleware/admin/notAuth.js')
middleware['admin/notAuth'] = middleware['admin/notAuth'].default || middleware['admin/notAuth']

middleware['frontend/auth'] = require('../middleware/frontend/auth.js')
middleware['frontend/auth'] = middleware['frontend/auth'].default || middleware['frontend/auth']

middleware['frontend/notAuth'] = require('../middleware/frontend/notAuth.js')
middleware['frontend/notAuth'] = middleware['frontend/notAuth'].default || middleware['frontend/notAuth']

middleware['frontend/verify-email'] = require('../middleware/frontend/verify-email.js')
middleware['frontend/verify-email'] = middleware['frontend/verify-email'].default || middleware['frontend/verify-email']

middleware['frontend/verify'] = require('../middleware/frontend/verify.js')
middleware['frontend/verify'] = middleware['frontend/verify'].default || middleware['frontend/verify']

export default middleware
