export default () => ({
  user: null,
  token: null,
  headers: null,
  login: {
    isSuccess: false,
    isAlert: false,
    message: ''
  }
})
