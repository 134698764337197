//
//
//
//
//
//
//
//

export default {
  name: 'f-page-title',
}
