/**
 * Class Notify global
 * @package vue-sweetalert2 ^3.0.3
 *
 * @property confirmDeleting()
 * @property success()
 * @property error()
 */

import Swal from 'sweetalert2'
import {RESPONSE_SUCCESS} from "../contants/api";

let Notify;

export default Notify = {
  async confirmDeleting(callback, configs, redirect = null) {
    const confirmedResult = await Notify.confirm(configs)
    if (!confirmedResult.isConfirmed) {
      return null
    }
    const destroyData = await callback()
    if (destroyData.status) {
      Notify.success({title: destroyData.message})
    } else {
      Notify.error({title: 'Error ' + result.status, text: result.message});
    }
    return destroyData
  },
  async confirmAction(callback, configs) {
    const confirmedResult = await Notify.confirm(configs)
    if (!confirmedResult.isConfirmed) {
      return null
    }
    const confirmData = await callback()
    console.log('confirmData', confirmData);
    if (!confirmData.errorCode) {
      Notify.success({title: confirmData.message})
    } else {
      Notify.error({title: 'エラー ' + result.code, text: result.data.message});
    }
    return confirmData
  },
  confirm(configs) {
    const defaultConfig = {
      text: 'カテゴリを削除します。よろしいですか。',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'キャンセル',
      confirmButtonText: '削除',
      confirmButtonColor: '#dc3545',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    };

    configs = {...defaultConfig, ...configs};

    return Swal.fire(configs)
  },
  error(configs) {
    const defaultConfig = {
      title: 'エラー',
      text: null,
      icon: 'error',
      allowOutsideClick: false,
      showCancelButton: false
    };

    configs = {...defaultConfig, ...configs}

    return Swal.fire(configs)
  },
  success(configs = {}) {
    const defaultConfig = {
      title: null,
      icon: 'success',
      timer: 1800,
      showConfirmButton: false,
      allowOutsideClick: false,
    };

    configs = {...defaultConfig, ...configs}

    return Swal.fire(configs)
  }
}
