export default (context, inject) => {
  const helper = {
    getCompanyRegistrationNumber(company) {
      return `${company.corporate_number} / ${company.company_number}`;
    },
    getRecipientAddress(address) {
      return `${address.address1}${address.address2}${address.building_name || ""}`;
    },
    isNotEmptyObject(obj) {
      return obj &&
        Object.keys(obj).length !== 0
    },
    toFullWidth(value) {
      return value.replace(/[A-Za-z0-9]/g, function(s) {return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);});
    },
    toHalfWidth(value) {
      return value.replace(/[！-～]/g, function(s) {return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);});
    }
  }
  inject('helper', helper);
}
