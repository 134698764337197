import {ADMIN_VERSION_API} from "@/contants/api";
const userRoute = `${ADMIN_VERSION_API}/users`;

export default $axios => ({
  index() {
    return $axios.get(userRoute);
  },

  show(id) {
    return $axios.get(`${userRoute}/${id}`);
  },

  destroy(id) {
    return $axios.delete(`${userRoute}/${id}`);
  },

  login({ username, password }) {
    return $axios.post("api/admin/v1/login", { username, password });
  },

  logout() {
    return $axios.get('api/admin/v1/logout');
  }
});
