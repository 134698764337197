//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'f-footer-register'
}
