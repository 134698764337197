//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex"

export default {
  name: 'f-header-lp',
  computed: {
    ...mapState({
      user: state => state.frontend.auth.user
    }),
  }
}
