import {FRONTEND_VERSION_API} from "@/contants/api";
const stripeUrl = FRONTEND_VERSION_API;

export default $axios => ({
  getPaymentMethod() {
    return $axios.post(`${stripeUrl}/get-payment-methods`);
  },
  getStripeCharge(payload) {
    return $axios.put(`${stripeUrl}/stripe-charge/${payload.project_id}`, payload);
  },
  submitPayment(payload) {
    return $axios.put(`${stripeUrl}/projects/submit-payment/${payload}`);
  },
});
