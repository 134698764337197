import {ADMIN_VERSION_API} from "@/contants/api";
const paymentConfirmresource = `${ADMIN_VERSION_API}/payment-confirms`;

export default $axios => ({
  index() {
    return $axios.get(paymentConfirmresource);
  },

  update(id) {
    return $axios.put(`${paymentConfirmresource}/${id}`);
  }
});
