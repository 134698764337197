//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'f-header',
  computed: {
    ...mapGetters({
      user: 'frontend/auth/getUser'
    })
  },
  methods: {
    ...mapActions({
      logout: 'frontend/auth/logout'
    }),
    async doLogout(context) {
      this.$nuxt.$loading.start()
      await this.logout()
      this.$nuxt.$loading.finish()
    }
  }
}
