//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    configsOfBreadCrumb() {
      return this.$store.getters.getConfigsOfBreadCrumb('admin');
    },
  },
  methods: {
    isLast(index) {
      return index === this.configsOfBreadCrumb.length - 1;
    },
    breadCrumb({name, label = null, params = {}}) {
      return label || this.$t(`layouts.${name}`, params)
    }
  }
};
