import {FRONTEND_VERSION_API} from "@/contants/api";
const projectRepository = `${FRONTEND_VERSION_API}/projects`;

export default $axios => ({
  index() {
    return $axios.get(projectRepository);
  },
  show(id) {
    return $axios.get(`${projectRepository}/${id}`);
  },
  reoderProject(id) {
    return $axios.get(`${projectRepository}/re-oder-project/${id}`);
  },
  getAllConfigs() {
    return $axios.get(`${projectRepository}/get-all-configs`);
  },
  updateReceiptPayment(payload) {
    return $axios.put(`${projectRepository}/update-receipt-payment/${payload.hash_id}`, payload);
  },
  getSubmissionAddress(address) {
    return $axios.post(`${projectRepository}/get-submission-address`, {
      address: address
    });
  }
});
