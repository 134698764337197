import Notify from '../mixins/notify'

export default function ({app, redirect, store, error, route}) {
  app.$axios.onRequest(config => {
    const bearToken = store.getters[store.state.fromPage + '/auth/getToken']
    bearToken && (config.headers['Authorization'] = bearToken)
    return config
  })

  app.$axios.onResponse(response => {
    const new_user = response?.data?.new_user
    if (new_user) store.commit('frontend/auth/setUser', new_user)
    resetToken({store, response})
    const data = response?.data
    return data
  })

  app.$axios.onResponseError(err => {
    if (['TokenBlacklist', 'TokenInvalid'].includes(err.response?.data?.status)) {
      removeAuth({app, redirect, store})
    }
    const new_user = err.response?.data?.new_user
    if (new_user) store.commit('frontend/auth/setUser', new_user)
    (!app.$config.baseURL ? fromClientAxiosError(err, route) : fromServerAxiosError(err, route))
    const response = err.response
    resetToken({store, response})
    return Promise.resolve({
      errorCode: err.response?.status,
      data: err.response?.data
    })
  })

  const fromServerAxiosError = (err, route) => {
    const redirectUri = route.fullPath;
    const code = parseInt(err.response && err.response?.status)
    switch (code) {
      case 401:
        removeAuth({app, redirect, store})
      default:
        let redirectPath = '/dashboard';
        let redirectText = null;
        if (/^(\/auth\/verify)/.test(route.path)) {
          redirectPath = '/auth/login?redirect=' + redirectUri;
          redirectText = 'ログイン';
        }
        if (store.state.fromPage === 'admin') {
          redirectPath = '/admin';
        }
        return error({
          message: err.response?.data.hasOwnProperty('data')
            ? Object.values(err.response?.data.data)[0][0]
            : err.response?.data?.message || (`${err.response?.status} - ${err.response.statusText}`) || 'Server Error',
          redirectPath,
          redirectText
        });
    }
  }

  const fromClientAxiosError = (err) => {
    const code = parseInt(err.response && err.response?.status);
    switch (code) {
      case 422:
      case 400:
        break;
      case 401:
        removeAuth({app, redirect, store})
        break;
      default:
        Notify.error({text: err.response?.data.message});
    }
  }
}

function resetToken({store, response}) {
  const data = response?.data
  if (data && data.auth) {
    const tokenBearer = `${data.auth.token_type} ${data.auth.access_token}`
    data = {data: data.auth}
    if (store.state.fromPage === 'admin') {
      store.dispatch('admin/auth/saveAuth', {tokenBearer})
    } else {
      store.dispatch('frontend/auth/saveAuth', data)
    }
  }
}

function removeAuth({app, redirect, store}) {
  console.log(`${store.state.fromPage}Token`);
  app.$cookiz.remove(`${store.state.fromPage}Token`, {
    domain: app.$config.cookieDomain,
    path: '/'
  });
  store.commit(`${store.state.fromPage}/auth/setToken`, null);
  if (store.state.fromPage === 'admin') {
    return redirect('/admin/auth/login');
  } else {
    return redirect('/');
  }
}
