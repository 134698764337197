export default () => ({
    user: null,
    token: null,
    headers: null,
    login: {
      isLogin: false,
      isSuccess: false,
      isAlert: false,
      message: ''
    }
  })
