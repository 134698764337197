export async function store({}, payload) {
  const data = await this.$frontendRepositories.seals.store(payload);
  return data;
}
export async function update({}, payload) {
  const data = await this.$frontendRepositories.seals.update(payload);
  return data;
}
export async function getByUserAndCompany({}, payload) {
  const data = await this.$frontendRepositories.seals.getByUserAndCompany(payload);
  return data;
}
