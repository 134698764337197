//
//
//
//
//
//
//
//
//
//

import settingMenus from '~/contants/settingMenu';

export default {
  data() {
    return {
      settingMenus: settingMenus
    }
  }
}
