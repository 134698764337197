export default {
  items: [
    {
      name: 'ダッシュボード',
      url: '/',
      icon: 'las la-home'
    },
    {
      name: 'ワークフロー',
      icon: 'las la-flag',
      children: [
        {
          name: 'Todo',
          url: '/todo'
        },
        {
          name: '提案一覧',
          url: '/proposals'
        },
        {
          name: '提案の作成',
          url: '/proposals/create'
        },
        {
          name: 'テンプレート一覧', // List template created by ls
          url: '/ls-templates',
        }
      ],
    },
    {
      name: '社内マニュアル',
      icon: 'las la-check-square',
      children: [
        {
          name: '社内マニュアル一覧', // List template created by company
          url: '/templates',
        },
        {
          name: '社内マニュアル作成',
          url: '/templates/create',
        }
      ],
    },
    {
      name: '設定',
      icon: 'las la-cog',
      children: [
        {
          name: 'タグ一覧',
          url: '/proposals/tag'
        }
      ],
    },
    {
      name: 'カレンダー',
      url: '/calendar',
      icon: 'las la-calendar'
    },
    {
      name: '経営管理',
      url: '/',
      icon: 'las la-newspaper',
      children: [
        {
          name: '助成金検索',
          url: '/'
        }
      ]
    },
    {
      name: '書類管理',
      url: '/',
      icon: 'las la-folder-open',
      children: [
        {
          name: '書類一覧',
          url: '/document'
        },
        {
          name: 'カテゴリー一覧',
          url: '/document/category'
        }
      ]
    },
    {
      name: '書類の作成',
      url: '/',
      icon: 'las la-file-alt',
      children: [
        {
          name: '新しい書類の作成',
          url: '/document-templates'
        },
        {
          name: '作成中の書類',
          url: '/',
          badge: true
        },
        {
          name: 'テンプレート',
          url: '/'
        }
      ]
    },
    {
      name: 'ヘルプ',
      url: '/',
      icon: 'las la-question-circle'
    }
  ]
}
