import {FRONTEND_VERSION_API} from "@/contants/api";
const sealCertificateResource = `${FRONTEND_VERSION_API}/request-seal-certificate`;

export default $axios => ({
  storeStep1(payload) {
    return $axios.post(`${sealCertificateResource}/step1`, payload);
  },
  storeStep2(payload) {
    return $axios.post(`${sealCertificateResource}/step2`, payload);
  },
  getSealCertificateConfig() {
    return $axios.get(`${sealCertificateResource}/get-seal-certificate-config`);
  },
  getSealCertificate(payload) {
    return $axios.get(`${sealCertificateResource}/get-seal-certificate/${payload}`);
  }
});
