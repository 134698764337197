import {FRONTEND_VERSION_API} from "@/contants/api";
const sealCardSettingUrl = `${FRONTEND_VERSION_API}/digital-certificate-settings`;

export default $axios => ({
  store(payload) {
    return $axios.post(sealCardSettingUrl, payload);
  },
  getByUserAndCompany(payload) {
    return $axios.get(`${sealCardSettingUrl}/get-by-user-and-company?company_id=${payload.company_id}`);
  }
});
