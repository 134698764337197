//
//
//
//
//
//
//
//
//

import nav from './menu';
import {
  FFooterRegister,
} from '~/components/frontend';

export default {
  name: 'frontend-layout',
  middleware: "frontend/notAuth",
  head() {
    return {
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''}
      ],
      link: [
        {rel: 'stylesheet', type: 'text/css', href: '/frontend/css/style.css'},
      ]
    }
  },
  components: {
    FFooterRegister,
  },
}
