export async function index({commit}) {
  const data = await this.$adminRepositories.revenues.index();
  commit('setList', data?.data || []);
  commit('setTotalRevenue');
  return data;
}

export async function show({}, payload) {
  const data = await this.$adminRepositories.revenues.show(payload);
  return data;
}

export async function updateSikuliPayment({dispatch}, payload) {
  await this.$adminRepositories.revenues.updateSikuliPayment(payload);
  dispatch('index')
}
