import {FRONTEND_VERSION_API} from "@/contants/api";
const companyRequestResource = `${FRONTEND_VERSION_API}/request-company-registration`;

export default $axios => ({
  storeStep1(payload) {
    return $axios.post(`${companyRequestResource}/step1`, payload);
  },
  storeStep2(payload) {
    return $axios.post(`${companyRequestResource}/step2`, payload);
  },
  getRecipientInfo(payload) {
    // @TODO: incomplete
    return {
      recipient_name: '',
      zip_code: '',
      address: '',
    }
  },
  getCompanyRequestConfig() {
    return $axios.get(`${companyRequestResource}/get-company-request-config`);
  },
  getCompanyRequest(payload) {
    return $axios.get(`${companyRequestResource}/get-company-request/${payload}`);
  },
  storeStep1Temp(payload) {
    return $axios.post(`${companyRequestResource}/store-step1-temp`, payload);
  }
});
