import {ADMIN_VERSION_API} from "@/contants/api";
const resource = `${ADMIN_VERSION_API}/projects`;

export default $axios => ({
  // index() {
  //   return $axios.get(`${resource}.json`);
  // },

  // show(id) {
  //   return $axios.get(`${resource}/${id}.json`);
  // },

  // delete(id) {
  //   return $axios.delete(`${resource}/${id}.json`);
  // },

  getAllConfigs() {
    return $axios.get(`${resource}/get-all-configs`);
  },

  getProjectNeedPayment() {
    return $axios.get(`${resource}/need-payment`);
  },

  updateSikulixPayment(id) {
    return $axios.put(`${resource}/update-sikulix-payment/${id}`);
  },

  updateMemo({id, payload}) {
    return $axios.put(`${resource}/update-memo/${id}`, payload);
  }
});
