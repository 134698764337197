import { render, staticRenderFns } from "./FooterRegister.vue?vue&type=template&id=00174cd4&"
import script from "./FooterRegister.vue?vue&type=script&lang=js&"
export * from "./FooterRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/app/components/admin/Footer.vue').default})
