// import {RESPONSE_SUCCESS} from '@@/contants/api'
const RESPONSE_SUCCESS = "response.succeeded"

export async function initAuthState({commit, dispatch}) {
  let tokenBearer = this.$cookiz.get('adminToken') || null;
  if (tokenBearer) {
    commit('setToken', tokenBearer);
  }
}

export async function login({ dispatch, commit }, payload) {
  commit("setBtnLoading", true, { root: true });
  let isSuccess = false;
  const data = await this.$adminRepositories.users.login({
    username: payload.username,
    password: payload.password
  });
  if (data && data.status === RESPONSE_SUCCESS) {
    isSuccess = true;
    const tokenType = data.data.token_type;
    const accessToken = data.data.access_token;

    const tokenBearer = tokenType + " " + accessToken;
    await dispatch("saveAuth", { tokenBearer });
  }
  commit("setBtnLoading", false, { root: true });
  return commit("setLogin", {
    isSuccess: isSuccess,
    message: data.message,
    isAlert: true
  });
}

export async function logout({ commit }) {
  const data = await this.$adminRepositories.users.logout();
  if (data && data.status === RESPONSE_SUCCESS) {
    commit("setUser", null);
    commit("setToken", null);
    this.$cookiz.remove("adminToken");
    return true;
  }
  return false;
}

export async function saveAuth({ commit }, payload) {
  const tokenBearer = payload.tokenBearer;
  commit("setToken", tokenBearer);
  this.$cookiz.set("adminToken", tokenBearer);
}
