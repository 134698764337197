import {FRONTEND_VERSION_API} from "@/contants/api";
const digitalCertificateResource = `${FRONTEND_VERSION_API}/request-digital-certificate`;

export default $axios => ({
  storeStep1(payload) {
    return $axios.post(`${digitalCertificateResource}/step1`, payload);
  },
  storeStep2(payload) {
    return $axios.post(`${digitalCertificateResource}/step2`, payload);
  },
  updateDeliverInfo(payload) {
    return $axios.post(`${digitalCertificateResource}/update-deliver-info`, payload);
  },
  // getDigitalCertificateConfig() {
  //   return $axios.get(`${digitalCertificateResource}/get-digital-certificate-config`);
  // },
  getDigitalCertificate(payload) {
    return $axios.get(`${digitalCertificateResource}/get-digital-certificate/${payload}`);
  }
});
