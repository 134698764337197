export async function initAuthState({commit, dispatch}) {
  const tokenBearer = this.$cookiz.get('frontendToken', {
    domain: this.$config.cookieDomain,
    path: '/'
  }) || null;
  if (tokenBearer) {
    commit('setToken', tokenBearer);
    await dispatch('getUserInfo', {tokenBearer});
  }
}


export async function verify({}, payload) {
  const data = await this.$frontendRepositories.auth.verify(payload);
  return data;
}

export async function verifyEmailChangeEmail({}, payload) {
  const data = await this.$frontendRepositories.auth.verifyEmailChangeEmail(payload);
  return data;
}

export async function register({}, payload) {
  const data = await this.$frontendRepositories.auth.register(payload);
  return data
}

export async function login({}, payload) {
  const data = await this.$frontendRepositories.auth.login(payload);
  return data
}

export async function logout({dispatch}) {
  await this.$frontendRepositories.auth.logout();
  return this.$router.go('/auth/login')
}

export async function update({}, payload) {
  const data = await this.$frontendRepositories.auth.update(payload);
  return data;
}

export async function saveAuth({commit}, payload) {
  let tokenType = payload.data.token_type;
  let accessToken = payload.data.access_token;
  let tokenBearer = tokenType + ' ' + accessToken;
  commit('setToken', tokenBearer);
  this.$cookiz.set('frontendToken', tokenBearer, {
    domain: this.$config.cookieDomain,
    path: '/'
  });
}

export async function getUserInfo({commit}) {
  const {data} = await this.$frontendRepositories.auth.info();
  commit('setUser', data?.user)
  this.$cookiz.set('user', data?.user);
}

export async function updateFirstLogin({state, commit}, payload) {
  const data = await this.$frontendRepositories.auth.updateFirstLogin({firstLogin: payload});
  if (!data.errorCode) {
    commit('updateUserFirstLogin', data)
  }
  return data
}
