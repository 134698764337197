export async function setConfigs({}, payload) {
  const {data} = await this.$frontendRepositories.companies.search(payload);
  return data;
}

export async function getCompanyRequestConfig({getters, commit}) {
  let data = getters['getConfigs']
  if(!data) {
    data = await this.$frontendRepositories.company_request.getCompanyRequestConfig();
    data = data.data
  }
  commit('setConfigs', data)
  return data;
}

export async function getSealCertificateConfig({getters, commit}) {
  let data = getters['getSealConfigs']
  if(!data) {
    data = await this.$frontendRepositories.seal_certificate.getSealCertificateConfig();
    data = data.data
  }
  commit('setSealConfigs', data)
  return data;
}
