import {RESPONSE_SUCCESS} from "@/contants/api";

export default async function ({store, query, redirect}) {
  let token = query.token || '';
  let params = {token: token};
  const data =  await store.dispatch('frontend/auth/verifyEmailChangeEmail', params);
  if (data.status === RESPONSE_SUCCESS) {
    return redirect('/auth/login')
  }
}
