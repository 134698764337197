export async function search({commit}, payload) {
  const {data} = await this.$frontendRepositories.companies.search(payload);
  if (data && data.status != false) {
    commit('setCompanySearchResult', data)
    return data;
  }
  commit('setCompanySearchResult', {})
  return {};
}

export async function checkRequireSettingForSealRequest({}, payload) {
  const {data} = await this.$frontendRepositories.companies.checkRequireSettingForSealRequest(payload);
  return data;
}

export async function checkRequireSettingForDigitalRequest({}, payload) {
  const {data} = await this.$frontendRepositories.companies.checkRequireSettingForDigitalRequest(payload);
  return data;
}

export async function getByCurrUserId({}) {
  const data = await this.$frontendRepositories.companies.getByCurrUserId();
  return data;
}

export async function create({_}, payload) {
  const data = await this.$frontendRepositories.companies.create(payload);
  return data;
}

export function setCompanies({commit, dispatch, state}, {companies, companyId}) {
  commit('setCompanies', companies)
  const selectedCompanyId = state.selectedCompanyId
  if (this.$helper.isNotEmptyObject(companies) && (!companies[selectedCompanyId] || companyId)) {
    const defautCompanyId = companyId ? companyId : Object.keys(companies)[0];
    dispatch('setSelectedCompanyId', defautCompanyId)
  }
}

export async function getCompanies({dispatch, state}, payload = {}) {
  let companies = state.companies;
  if (!this.$helper.isNotEmptyObject(companies)) {
    const data = await dispatch('getByCurrUserId')
    companies = data.companies
    await dispatch('setCompanies', {companies, companyId: payload.companyId})
  }
  return companies || {}
}

export async function getCompany({state, dispatch}, payload = {}) {
  let companies = await dispatch('getCompanies', payload)
  const selectedCompanyId = state.selectedCompanyId
  return companies[selectedCompanyId] || {}
}

export function setSelectedCompanyId({commit}, payload) {
  commit('setSelectedCompanyId', payload)
  this.$cookiz.set('selectedCompanyId', payload)
}

export function getSelectedCompanyFromCookie({commit}) {
  const selectedCompanyId = this.$cookiz.get('selectedCompanyId')
  if (selectedCompanyId) {
    commit('setSelectedCompanyId', selectedCompanyId)
  }
}

export async function getRequestCompany({dispatch, store}, payload) {
  let companies = store.state.frontend.companies.companies
  if (!this.$helper.isNotEmptyObject(companies)) {
    companies = await dispatch('frontend/companies/getCompanies')
  }
}

export async function pushCompanies({commit, state, dispatch}, payload) {
  let companies = {...state.companies}
  companies[payload.id] = payload;
  await commit('setCompanies', companies)
  await dispatch('setSelectedCompanyId', payload.id)
}

export async function setSearchedCompany({commit}, payload) {
  await commit('setSearchedCompany', payload)
}

export async function getSubmissionAddressByCompanyId({commit}, payload) {
  return this.$frontendRepositories.companies.getSubmissionAddressByCompanyId(payload);
}
