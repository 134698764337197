//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
export default {
  name: "header-dropdown",
  methods: {
    ...mapActions({
      logout: "admin/auth/logout"
    }),
    async doLogout(context) {
      try {
        const isLogout = await this.logout();
        if (isLogout) {
          this.$router.push("/admin/auth/login");
        }
      } catch (e) {
      }
    }
  }
};
