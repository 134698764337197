import {FRONTEND_VERSION_API} from "@/contants/api";
const submitPersion = `${FRONTEND_VERSION_API}/submmit-person-settings`;

export default $axios => ({
  store(payload) {
    return $axios.post(submitPersion, payload);
  },
  getByUserAndCompany(payload) {
    return $axios.get(`${submitPersion}/get-by-user-and-company?company_id=${payload.company_id}`);
  }
});
