export default {
  items: [
    {
      name: 'layouts.dashboard',
      url: '/admin',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary'
      }
    },
    {
      name: 'layouts.companies',
      url: '/admin/companies',
      icon: 'icon-people'
    },
    {
      name: 'layouts.users',
      url: '/admin/users',
      icon: 'icon-user'
    },
    {
      name: 'layouts.payment_confirms',
      url: '/admin/payment_confirms',
      icon: 'icon-list'
    },
    {
      name: 'layouts.revenues',
      url: '/admin/revenues',
      icon: 'icon-list'
    },
    {
      name: 'layouts.need_payment',
      url: '/admin/need_payment',
      icon: 'icon-credit-card'
    },
    {
      name: 'layouts.revenue_report',
      icon: 'icon-pie-chart',
      children: [
        {
          name: 'layouts.list',
          url: '/admin/revenue_reports/list',
          icon: 'icon-list'
        },
        {
          name: 'layouts.chart',
          url: '/admin/revenue_reports/chart',
          icon: 'icon-chart'
        }
      ]
    },
    {
      name: 'layouts.contacts',
      url: '/admin/contacts',
      icon: 'icon-phone'
    },
    {
      name: 'layouts.change_sikulix_pass',
      url: '/admin/change-sikulix-pass',
      icon: 'fa fa-key'
    },
  ]
}
