import {SEAL_CERTIFICATE} from '@/contants/form';
// import {cloneDeep} from 'lodash/clonedeep'
// const clonedeep = require('lodash.clonedeep')

export function setSealCertificate({commit}, payload) {
  commit('setSealCertificate', Object.assign({}, payload))
}

export async function getSealCertificate({getters, commit}, payload) {
  if (!payload) {
    return SEAL_CERTIFICATE;
  } else {
    let seal_certificate = getters['getSealCertificate'];
    if (!seal_certificate || seal_certificate.id != payload) {
      seal_certificate = await this.$frontendRepositories.seal_certificate.getSealCertificate(payload)
      commit('setSealCertificate', seal_certificate);
    }
    const data = JSON.parse(JSON.stringify(seal_certificate));
    return data
  }
}

export async function storeStep1({}, payload) {
  const data = await this.$frontendRepositories.seal_certificate.storeStep1(payload);
  return data;
}

export async function storeStep2({}, payload) {
  const data = await this.$frontendRepositories.seal_certificate.storeStep2(payload);
  return data;
}

export async function getRecipientInfo({}, payload) {
  const data = await this.$frontendRepositories.seal_certificate.getRecipientInfo(payload);
  return data;
}
