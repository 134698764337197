import {FRONTEND_VERSION_API} from "@/contants/api";
const accountResource = `${FRONTEND_VERSION_API}/soft-account-settings`;

export default $axios => ({
  show() {
    return $axios.get(`${accountResource}/get-by-curr-user`);
  },
  store(payload) {
    return $axios.post(accountResource, payload);
  }
});
