import {COMPANY_REQUEST} from '@/contants/form';
// import {cloneDeep} from 'lodash/clonedeep'
// const clonedeep = require('lodash.clonedeep')

export function setRequestCompany({commit}, payload) {
  commit('setRequestCompany', Object.assign({}, payload))
}

export async function getRequestCompany({getters, commit, dispatch}, payload) {
  if (!payload) {
    return COMPANY_REQUEST;
  } else {
    let company_request = getters['getRequestCompany'];
    if (!company_request || company_request.id != payload) {
      company_request = await this.$frontendRepositories.company_request.getCompanyRequest(payload)
      commit('setRequestCompany', company_request)
    }
    // return deepClone(getters['getRequestCompany']);
    // return Object.assign({},getters['getRequestCompany']);
    const data = JSON.parse(JSON.stringify(getters['getRequestCompany']));
    return data
  }
}

export async function storeStep1({}, payload) {
  const data = await this.$frontendRepositories.company_request.storeStep1(payload);
  return data;
}

export async function storeStep2({}, payload) {
  const data = await this.$frontendRepositories.company_request.storeStep2(payload);
  return data;
}

export async function getRecipientInfo({}, payload) {
  const data = await this.$frontendRepositories.company_request.getRecipientInfo(payload);
  return data;
}

export async function storeStep1Temp({}, payload) {
  const data = await this.$frontendRepositories.company_request.storeStep1Temp(payload);
  return data;
}

export async function getAddressByZipCode({commit}, payload) {
  return await this.$frontendRepositories.googleService.getAddressByZipCode(payload);
}
