import ProjectRepository from "./repositories/ProjectRepository";
import AuthRepository from "./repositories/AuthRepository";
import CompanyRepository from "./repositories/CompanyRepository";
import DigitalRepository from "./repositories/DigitalRepository";
import SealRepository from "./repositories/SealRepository";
import AccountRepository from "./repositories/AccountRepository";
import SubmitPersonRepository from "./repositories/SubmitPersonRepository";
import CompanyRequestRepository from "./repositories/CompanyRequestRepository";
import SealCertificateRepository from "./repositories/SealCertificateRepository";
import DigitalCertificateRepository from "./repositories/DigitalCertificateRepository";
import StripeRepository from "./repositories/StripeRepository";
import ContactRepository from "./repositories/ContactRepository";
import GoogleServiceRepository from "./repositories/googleServiceRepository";

export default ($axios) => ({
  projects: ProjectRepository($axios),
  companies: CompanyRepository($axios),
  digitals: DigitalRepository($axios),
  seals: SealRepository($axios),
  auth: AuthRepository($axios),
  accounts: AccountRepository($axios),
  submitPersion: SubmitPersonRepository($axios),
  company_request: CompanyRequestRepository($axios),
  seal_certificate: SealCertificateRepository($axios),
  digital_certificate: DigitalCertificateRepository($axios),
  stripe: StripeRepository($axios),
  contact: ContactRepository($axios),
  googleService: GoogleServiceRepository($axios)
});
