export async function index() {
  const {data} = await this.$frontendRepositories.projects.index();
  return data;
}

export async function show({}, payload) {
  const {data} = await this.$frontendRepositories.projects.show(payload);
  return data;
}

export async function reoderProject({}, payload) {
  const {data} =  await this.$frontendRepositories.projects.reoderProject(payload)
  return data
}

export async function getAllConfigs({}) {
  const {data} = await this.$frontendRepositories.projects.getAllConfigs()
  return data
}

export async function updateReceiptPayment({}, payload) {
  const {data} = await this.$frontendRepositories.projects.updateReceiptPayment(payload)
  return data
}

export async function getSubmissionAddress({}, address) {
  const {data} = await this.$frontendRepositories.projects.getSubmissionAddress(address)
  return data
}
