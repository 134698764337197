import {ADMIN_VERSION_API} from "@/contants/api";
const revenueRoute = `${ADMIN_VERSION_API}/revenues`;

export default $axios => ({
  index() {
    return $axios.get(revenueRoute);
  },

  show(id) {
    return $axios.get(`${revenueRoute}/${id}`);
  },

  updateSikuliPayment(payload) {
    return $axios.put(`${revenueRoute}/update-sikuli-payment/${payload.projectId}`, payload);
  }
});
