export async function index({}) {
  const data = await this.$adminRepositories.users.index();
  return data;
}

export async function destroy({}, payload) {
  const data = await this.$adminRepositories.users.destroy(payload.id);
  return data;
}

export async function show({}, payload) {
  const data = await this.$adminRepositories.users.show(payload);
  return data;
}
