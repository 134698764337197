//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'f-footer'
}
