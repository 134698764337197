//
//
//
//
//
//

export default {
  head() {
    return {
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''}
      ],
      link: [
        {rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
        {rel: 'stylesheet', type: 'text/css', href: '/admin/css/style.css'},
      ],
    }
  },
}
