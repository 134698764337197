import {ADMIN_VERSION_API} from "@/contants/api";
const companyRoute = `${ADMIN_VERSION_API}/companies`;

export default $axios => ({
  index() {
    return $axios.get(companyRoute);
  },

  show(id) {
    return $axios.get(`${companyRoute}/${id}`);
  },

  destroy(id) {
    return $axios.delete(`${companyRoute}/${id}`);
  }
});
