import {ADMIN_VERSION_API} from "~/contants/api";

export default $axios => ({
  getAccount() {
    return $axios.get(`${ADMIN_VERSION_API}/sikulix/get-account`);
  },
  update(payload) {
    return $axios.put(`${ADMIN_VERSION_API}/sikulix/change-password`, payload);
  }
});
