import {RESPONSE_SUCCESS} from "@/contants/api";

export default async function ({store, query, redirect}) {
  const token = query.token || ''
  const continueCompanyRequest = query.continueCompanyRequest || ''
  const params = {token: token}
  console.log(continueCompanyRequest);
  const data =  await store.dispatch('frontend/auth/verify', params);
  if (data.status === RESPONSE_SUCCESS) {
    await store.dispatch('frontend/auth/saveAuth', data);
    if (continueCompanyRequest != '') {
      return redirect(`/project/company-register/step2/${continueCompanyRequest}`)
    }
    return redirect('/dashboard')
  }
}
