import UserRepository from "./repositories/UserRepository";
import CompanyRepository from "./repositories/CompanyRepository";
import ProjectRepository from "./repositories/ProjectRepository";
import PaymentConfirmRepository from "./repositories/PaymentConfirmRepository";
import RevenueReportRepository from "./repositories/RevenueReportRepository";
import RevenueRepository from "./repositories/RevenueRepository";
import ContactRepository from "./repositories/ContactRepository";
import SikulixRepository from "./repositories/SikulixRepository";

export default ($axios) => ({
  users: UserRepository($axios),
  companies: CompanyRepository($axios),
  projects: ProjectRepository($axios),
  payment_confirms: PaymentConfirmRepository($axios),
  revenue_reports: RevenueReportRepository($axios),
  revenues: RevenueRepository($axios),
  contacts: ContactRepository($axios),
  sikulix: SikulixRepository($axios)
});
